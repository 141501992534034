
* {
	margin: 0;
	padding: 0;
}
body {
	height: 100%;
	margin: 0;
	padding: 0;
	background: #f5f8fa;
	display: flex;
	overflow-x: auto;
	min-width: 1050px;
	font-size: 14px;
	flex-direction: column;
	font-family: PingFang SC SourceHanSansCN-Regular, SourceHanSansCN, MicrosoftYaHeiSemibold, Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
img {
	vertical-align: middle;
}
.query-form {
    padding: 24px 16px 0px 35px;
	.ant-form-item .ant-form-item-control {
		width: 200px;
	}
	.ant-form-item {
		margin-bottom: 12px;
	}
}
.flex {
	display: flex;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-column {
	flex-direction: column;
}
.flex1 {
	flex: 1 1;
}
.item-center {
	align-items: center;
}
.dialog-form {
	max-height: 70vh;
	overflow-x: hidden;
}
.ant-form-item-label {
	font-weight: 500;
}
:global {
    .ant-form-item {
        margin-right: 1%;

        .ant-form-item-control {
            min-width: 150px;
            box-sizing: content-box;
        }
    }
    .ant-input {
		&:-webkit-autofill {
			background: #fff;
		}
	}
    input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0px 1000px rgba(225, 233, 255, 0) inset !important;
		-webkit-transition-delay: 99999s;
		-webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
	}
	.query-form {
		.ant-form-item-label {
			width: 120px ;
			text-align: left;
		}
	}
}
@--header-bgcolor: #2A3459;

.layout-contain {
	height: 100vh;
	width: 100%;
	display: flex;
	.outlet-box {
		flex: 1 1;
		overflow-x: hidden;
	}

	.side-menu-wrapper {
		background: #304156;
		overflow: hidden;
		&.collapsed {
			flex: 0 0 80px;
		}
		&.uncollapsed {
			flex: 0 0 210px;
		}

		.sidebar-logo-container {
			position: relative;
			width: 100%;
			height: 50px;
			line-height: 50px;
			background: #2b2f3a;
			text-align: center;
			overflow: hidden;

			svg {
				font-size: 28px;
				vertical-align: middle;
				width: 32px;
				height: 32px;
				vertical-align: middle;
				color: #4169e1;
			}

			h1 {
				display: inline-block;
				margin: 0;
				margin-left: 16px;
				color: #fff;
				font-weight: 600;
				line-height: 50px;
				font-size: 14px;
				font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
				vertical-align: middle;
			}
		}

		:global {

			.ant-menu-item,
			li.ant-menu-submenu {
				margin: 0 !important;
				background: #304156;
				line-height: 56px;
				ul.ant-menu-sub {
					li {
						background-color: #1f2d3d !important;
						height: 50px;
					}
				}

				/**
				&.ant-menu-submenu-open {
					svg {
						transform: translateY(-50%) rotate(-180deg);
					}
				}
				*/

				.ant-menu-submenu-title {
					margin: 0;
					height: 56px;

					/**
					height: 48px;
					*/
					line-height: 56px;

					&:active {
						background: #304156;
					}
				}

				.ant-menu-sub {
					.ant-menu-item {
						padding-left: 50px;
					}
				}

				.ant-menu-title-content {
					color: #fff;
				}

				a {
					color: #fff;
					&.active {
						color: rgb(64, 158, 255);
					}
				}
			}
			.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span {
				line-height: 56px;
			}
			.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
				background-color: transparent;
			}
			.ant-menu-inline > .ant-menu-item {
				height: 56px;
			}
			.ant-menu-dark.ant-menu-vertical .ant-menu-item {
				height: 56px;
			}
		}
	}
}

header.layout-header {
	display: inline-flex;
	height: 50px;
	width: 100%;
	align-items: center;
	background: #fff;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

	.breadcrumb {
		flex: 2 1;
		margin-left: 30px;
	}

	.infomation {
		float: right;
		display: inline-flex;
		align-items: center;

		.avatar {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			margin-right: 12px;
			align-items: center;

			svg {
				width: 24px;
				height: 24px;
				position: relative;
				top: -14px;
				color: #333;
			}
		}

		.account {
			font-weight: 400;
			font-size: 14px;
		}

		.logout {
			float: right;
			margin-left: 10px;
			cursor: pointer;
		}
	}
}

    :global {
        .ant-tabs-nav {
            &::before {
                content: '';
                border-bottom: none
            }
            div.ant-tabs-tab {
                padding: 16px 0;
                font-size: 16px;
                margin: 0 56px 0 0;
                font-weight: 400;
    
                &:hover {
                    color:#2878FF;
                }
        
                &.ant-tabs-tab-active {
                    color:#2878FF;
                    font-weight: 600;
                }
            }
        }
    }
